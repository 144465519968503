export default {
  colors: {
    primary: '#8257E6',
    background: '#FFFFFF',
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#6C6C80`,
    link: `#195FAA`,
    components: {
      blockquote: {
        background: `#feebc8`,
        text: `#2d3748`,
      },
    },
  },
};
